import React, { FC, useEffect, useState } from "react";
import cx from "classnames";
import Button from "components/Button";
import cross from "../../assets/images/common/close_btn.svg";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PK } from "../../config";
import { useAppDispatch, useAppSelector } from "store/hooks";
import success from "../../assets/images/common/success.svg";
import { ToastContainer, toast } from "react-toastify";

interface IProps {
  visible: boolean;
  onClose: () => void;
  title?: string;
  titleClassName?: string;
}

const AACPModal: FC<IProps> = ({ visible, onClose, title, titleClassName }) => {
  const { isLoading, all, isBuyLoading } = useAppSelector(
    state => state.crossSells
  );
  const [isTrialLoading, setIsTrialLoading] = useState(false);
  const [isSuccessVisible, setSuccessVisible] = useState(false);
  const { trial: isTrial } = useAppSelector(state => state.subscriptions.user);
  const { isAACPSelected } = useAppSelector(state => state.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (visible) {
      dispatch({ type: "GET_CROSS_SELLS" });
    }
  }, [visible]);

  useEffect(() => {
    if (!isBuyLoading) {
      dispatch({ type: "GET_USER_SUBSCRIPTIONS", onSuccess: () => {} });
    }
  }, [isBuyLoading]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isSuccessVisible) {
      timer = setTimeout(() => {
        setSuccessVisible(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isSuccessVisible]);

  const onPurschaseError = () => {
    toast.error(isTrial ? "Can't update" : "Cant purchase AACP", {
      position: "bottom-center"
    });
    setIsTrialLoading(false);
  };

  const onRedirect = (url: string) => {
    window.open(url, "_self");
  };

  const handlePurchase = () => {
    dispatch({
      type: "BUY_CROSS_SELL",
      id: all[0].id,
      onSuccess: () => {
        onClose();
        dispatch({ type: "GET_PROFILE" });
        setSuccessVisible(true);
      },
      onError: onPurschaseError,
      onRedirect
    });
  };

  const handleEndTrial = () => {
    setIsTrialLoading(true);
    dispatch({
      type: "END_TRIAL",
      onSuccess: () => {
        onClose();
        dispatch({ type: "GET_PROFILE" });
        setIsTrialLoading(false);
        setSuccessVisible(true);
      },
      onError: onPurschaseError
    });
  };

  return (
    <>
      <div className={cx("modal aacp-modal", { visible })}>
        <div className="scrollable-layer">
          {isTrial && isAACPSelected ? (
            <div className="body">
              <p className="aacp-title">ACCESS UNAVAILABLE DURING FREE TRIAL</p>
              <p className="message">
                Wanna access our Cleanse Plans now? Click below to end your trial + activate your paid membership.
              </p>
              <div className="footer-buttons">
                <button className="close-icon-button" onClick={onClose}>
                  <img src={cross} />
                </button>
                <Button
                  isLoading={isTrialLoading}
                  className=""
                  onClick={handleEndTrial}
                  disabled={isTrialLoading}
                >
                  ACTIVATE NOW
                </Button>
              </div>
              <p className="footer">
                By clicking this button, I agree to the{" "}
                <a
                  href="https://simplegreensmoothies.com/terms-of-use#subscription"
                  target="_blank"
                >
                  terms of use.
                </a>
              </p>
            </div>
          ) : (
            <div className="body">
              <p className={`aacp-title ${titleClassName ?? ""}`}>
                {title ?? "ALL-ACCESS CLEANSE PASS REQUIRED"}
              </p>
              <p className="message">
                {isTrial
                  ? `Wanna access our Cleanse Plans? Click below to end free trial + upgrade to the All-Access Cleanse Pass.`
                  : `Upgrade now to unlock access to cleanse plans + live cleanse events.`}
              </p>
              <div className="footer-buttons">
                <button className="close-icon-button" onClick={onClose}>
                  <img src={cross} />
                </button>
                <Button
                  isLoading={isLoading || isBuyLoading}
                  className=""
                  onClick={handlePurchase}
                  disabled={isLoading || isBuyLoading}
                >
                  UPGRADE: ${all[0]?.price}
                </Button>
              </div>
              {isTrial ? (
                <p className="footer">
                  By clicking this button, I agree to the{" "}
                  <a
                    href="https://simplegreensmoothies.com/terms-of-use#subscription"
                    target="_blank"
                  >
                    terms of use.
                  </a>
                </p>
              ) : (
                <span>one-time charge</span>
              )}
            </div>
          )}
        </div>
        <ToastContainer
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          toastClassName="toast error"
          limit={1}
        />
      </div>

      <div
        className={cx("modal save-plan-modal", {
          visible: isSuccessVisible
        })}
      >
        <div className="scrollable-layer">
          <div className="body">
            <div className="success-modal-mock">
              <img className="success-modal-mock_icon" src={success} />
              <p className="success-modal-mock_message">
                REQUEST IS BEING PROCESSED
              </p>
              <p className="success-modal-mock_text">
                In a few minutes, try refreshing the page to enjoy access to our
                Cleanse Plans.
              </p>
              <div className="success-modal-mock_divider" />
              <p className="success-modal-mock_close">
                ( window will close automatically )
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AACPModal;
